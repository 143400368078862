<template>
  <section id="description-list-alignment">
    <b-row class="match-height">
      <b-col md="12" v-for="plan in dataPlan" v-bind:key="plan.id">
        <b-card no-body>
          <b-card-header>
            <b-card-title> Perhatian </b-card-title>
          </b-card-header>
          <b-card-body>
            <dl class="row">
              <ol>
                <dd>
                  <li>
                    Baca dengan sekasama dan teliti ketika mengerjakan Ujian
                  </li>
                </dd>
                <dd>
                  <li>Pastikan koneksi internet anda bagus</li>
                </dd>
                <dd>
                  <li>Pilih browser dengan versi terbaru</li>
                </dd>
                <dd>
                  <li>
                    Kerjakan Soal Ujian sesuai dengan jadwal yang ditentukan
                  </li>
                </dd>
                <dd>
                  <li>Tidak ada penambahan waktu</li>
                </dd>
              </ol>
            </dl>
            <b-row>
              <b-col md="4"></b-col>
              <b-col md="4">
                <router-link :to="'/user/question_tryout/' + plan.plans_id">
                  <b-button @click="start_waktu()" variant="primary" block>
                    Mulai Kerjakan Ujian
                  </b-button>
                </router-link>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BTableSimple,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
    BCardHeader,
    BTableSimple,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      dataPlan: [],
      level: localStorage.getItem("Ulevel"),
      id_session: localStorage.getItem("Uid"),
      id_param: this.$route.params.id,
      id_durasi: this.$route.params.durasi,
      getUrl: "",
    };
  },
  async mounted() {
    this.checkAuth();
    await this.getPlan();

    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getPlan() {
      await this.$http
        // .get(process.env.VUE_APP_BACKEND_URL + "blogs/view")
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerplans/view3/` +
            this.id_session +
            `/` +
            this.id_param
        )
        .then((res) => {
          this.dataPlan = res.data.data;
        });
    },

    start_waktu() {
      // console.log("test", this.id_param);
      // console.log("test", this.id_durasi);
      this.$http.post(process.env.VUE_APP_BACKEND_URL + `registerplans/exam_start`, {
        durasiId: this.id_durasi,
        planId: this.id_param,
        sessionId: this.id_session,
      });
    },
    formatDate(value) {
      moment.locale("id");
      if (value != null) {
        const val = moment(value).format("ll");
        return val;
      }
    },
  },
};
</script>
